<template>
  <v-container>
    <div class="titulo">1) Escolha a especialidade</div>
    <v-autocomplete
      v-model="especialidade"
      :items="especialidades"
      :loading="loading.especilidade"
      no-data-text="Especialidade não encontrado"
      color="green"
      outlined
      dense
      item-text="GrupoProcedimento"
      return-object
      label="Especialidade"
      @change="getProfissional"
    />
    <div v-if="profissionais.length > 0">
      <div class="titulo">2) Escolha o profissional de saúde</div>
      <v-autocomplete
        v-model="profissional"
        :items="profissionais"
        :loading="loading.profissional"
        no-data-text="Nenhum profissional encontrado"
        color="green"
        outlined
        dense
        item-text="nome"
        item-value="id"
        return-object
        @change="getTipoExame"
        label="Profissional da saúde"
      />
    </div>
    <div v-if="procedimentos.length > 0">
      <div class="titulo">
        <div style="flex:1; padding: 5px;">{{profissionais.length > 0 ? 3 : 2}}) Escolha o procedimento</div>
        <div v-if="procedimento.Valor>0" style="border: 1px solid black;border-radius:5%; padding:5px">Valor: R$ {{format(procedimento.Valor)}}</div>
      </div>
      <v-autocomplete
        v-model="procedimento"
        :items="procedimentos"
        :loading="loading.procedimento"
        no-data-text="Nenhum procedimento encontrado"
        color="green"
        outlined
        dense
        item-text="nome"
        item-value="id"
        return-object
        label="Procedimentos"
      />
      <div v-if="procedimento.preparo" style="padding-left: 20px; font-weight: bold;">
        Observação: {{procedimento.preparo}}
      </div>
    </div>
    <div class="btnContainer">
      <v-btn
        @click="
          () =>callNextPage(profissional.id,profissional, especialidade,procedimento.id)
          
        "
        :disabled="habilitarBotaoProximo()"
      >
        <v-img src="../assets/IconEcon.png" alt="Logo" class="img" />
        Prosseguir
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import BaseService from "../services/BaseService";
import {formatMoney} from "../util/";

export default {
  data() {
    return {
      especialidade: "",
      profissional: "",
      procedimento: "",
      itemsEspecialidade: [],
      itemsExames: [],
      itemsProfissionais: [],
      itemsProcedimentos: [],
      loading: {
        especialidade: true,
        profissional: true,
        procedimento: true,
      },
    };
  },
  computed: {
    especialidades() {
      let items;
      if (this.itemsExames)
        items = this.itemsExames.map((item) => item.toUpperCase());
      return items;
    },
    profissionais() {
      let items;
      if (this.itemsProfissionais)
        items = this.itemsProfissionais.map((item) => ({
          nome: item.Nome.toUpperCase(),
          id: item.IdMedico,
        }));
        
      return items;
    },
    procedimentos() {
      let items;
      if (this.itemsProcedimentos)
        items = this.itemsProcedimentos.map((item) => ({
          nome: item.Procedimento.toUpperCase(),
          id: item.IdProcedimento,
          Valor: item.Valor,
          preparo: item.preparo,
        }));
        
      return items;
    },
  },
  async mounted() {
    const especialidadeService = new BaseService("exames/");
    try {
      const response = await especialidadeService.get();
      this.itemsEspecialidade = response.data;
      this.itemsEspecialidade = this.itemsEspecialidade.map(item=>{
        
        if(item.Virtual){
          item.GrupoProcedimento = item.Nome;
        }
        return item;
      })
      this.itemsExames = this.itemsEspecialidade.map(item => item.GrupoProcedimento)
                                .filter((value, index, self) => self.indexOf(value) === index)
      
      this.loading.especialidade = false;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading.especialidade = false;
    }
  },
  methods: {
    callNextPage(id, profissional, especialidade,  idProcedimento){
      localStorage.setItem("idProfissional", id);
      localStorage.setItem("idProcedimento", idProcedimento);
      localStorage.setItem("profissional",JSON.stringify(profissional));
      localStorage.setItem("especialidade" , JSON.stringify(especialidade));
      this.$router.push({
        name: 'SelecionaData',
        params: {
          profissional: this.profissional
        }
      })
    },
    format(value){
      return formatMoney(value);
    },
    habilitarBotaoProximo() {
      if (!this.especialidade) {
        return true;
      } else if (this.profissionais.length > 0) {
        if (!this.profissional) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async getProfissional() {
      const dados = this.itemsEspecialidade.filter(item => item.GrupoProcedimento === this.especialidade);
      
      this.itemsProfissionais = []; 
      this.itemsProcedimentos = [];

      if(dados.length == 1){
        const virtual = dados[0].Virtual;

        if(virtual){
          this.itemsProfissionais = [];
          this.profissional = {
            nome: dados[0].Nome,
            id: dados[0].IdMedico
          };
          this.getTipoExame();
        } else {
          this.itemsProfissionais = dados;
          this.loading.profissional = false;
        }
      } else if (dados.length > 1){
        this.itemsProfissionais = dados;
        this.loading.profissional = false;
      }
    },
    async getTipoExame() {
      const procedimentoService = new BaseService(
        `procedimentos/${this.profissional.id}`
      );
      this.procedimento = "";
      try {
        const response = await procedimentoService.get();
        this.itemsProcedimentos = response.data;
        this.loading.procedimento = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.procedimento = false;
      }
    },
  },
};
</script>
<style scoped>
.titulo {
  font-weight: bold;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.img {
  height: 28px;
  width: 28px;
  margin: 5px;
}
.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
</style>